import { getAnalytics } from '~/modules/analytics';
import { OneTimeAction } from '~/modules/api';
import type {
  OneTimeActionExecutionContext,
  OneTimeActionRunner,
} from '../types';

export const action = OneTimeAction.REPORT_USER_SIGNUP;

export const run = async (ctx: OneTimeActionExecutionContext) => {
  if (ctx.user) {
    const analytics = getAnalytics();
    analytics.reportSignUp(ctx.user);
    return true;
  }
  return false;
};

// NOTE: We normally do not use the default export, but we want to load runners dynamically
// so either we use the default export or we need to pick a name for an exported variable.
export default {
  action,
  run,
} satisfies OneTimeActionRunner;
