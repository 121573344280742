import { Skeleton } from '~/components/ui';
import { type ContentDto } from '~/modules/api';
import { hasAvailableThumbnail, isEmptyArray } from '~/utils';
import { PremiumItem } from './PremiumItem';

interface PremiumSlideshowProps {
  items: ContentDto[];
}

export function PremiumSlideshow(props: PremiumSlideshowProps) {
  if (isEmptyArray(props.items)) {
    return null;
  }

  const filteredSlides = props.items.filter(hasAvailableThumbnail);

  return <PremiumItem index={0} {...filteredSlides[0]} />;
}

export function FeaturedSlideshowSkeleton() {
  return <Skeleton class="size-full flex-[1] h-147 min-h-120 rounded-2xl" />;
}
