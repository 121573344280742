import { createSignal } from 'solid-js';
import {
  DreamFlareWordmarkIcon,
  PlayIcon,
  PremiumIcon,
} from '~/components/icons';
import { ThumbnailVideoPreview } from '~/components/shared';
import { Button, Slide } from '~/components/ui';
import { formatGenre } from '~/data/genres';
import type { ContentDto } from '~/libs/api-client';
import { cn, link } from '~/utils';

interface PremiumItemProps extends ContentDto {
  index: number;
  class?: string;
}

export function PremiumItem(props: PremiumItemProps) {
  const [isHover, setIsHover] = createSignal(false);

  const url = link.toSpin({ spinId: props.id, seriesId: undefined });

  return (
    <Slide
      id="premium-item-slide"
      class={cn(
        'relative overflow-hidden rounded-xl text-white !h-147 md:max-w-80',
        props.class,
      )}
    >
      <a
        href={url}
        class="group overflow-hidden"
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <ThumbnailVideoPreview
          id={props.id}
          thumbnails={props.thumbnails}
          alt={props.title}
          aspectRatio="ar3:4"
          important
          class="z-0 size-full"
          videoPreview={props.preview}
          hideVideoPreview={!isHover()}
          hideSpinner
          resolution="480p"
        />

        <div
          id="merits"
          class="absolute top-0 w-full h-36 bg-gradient-to-b from-black/85 py-6
            px-3"
        >
          <div class="flex items-center gap-4">
            <img
              class="h-12 object-cover"
              src="/images/logos/emmy-logo.png"
              alt="Emmy Logo"
            />
            <div>
              <p class="text-lg font-bold mb-1">From Emmy Winning Director</p>
              <p class="text-sm font-normal">Michaela Ternasky-Holland</p>
            </div>
          </div>
        </div>

        <div
          id="premium-tag"
          class="absolute right-0 bottom-[53%] size-9 rounded-lg rounded-r-none
            flex items-center justify-center bg-gradient-to-r from-violet-600
            to-violet-800 z-10"
        >
          <PremiumIcon class="size-5" />
        </div>

        <div class="absolute bottom-0 w-full">
          <div
            id="title-with-genre"
            class="flex flex-col text-center h-64 p-6 bg-gradient-to-t
              from-black/85"
          >
            <div class="text-base uppercase text-slate-400 font-bold mt-auto">
              {formatGenre(props.genres)}
            </div>
            <h2 class="text-xl font-bold leading-7">{props.title}</h2>
          </div>

          <div
            id="figures"
            class="relative w-full text-center backdrop-blur-md bg-black/50 p-8
              py-11 flex flex-col gap-9"
          >
            <div class="absolute -top-0.5 inset-x-0">
              <div
                class="border-grey-200 border-t-2 w-full border-dashed
                  border-spacing-4"
              />

              <div
                class="absolute left-0 -translate-x-1/2 -translate-y-1/2
                  rounded-full size-14 bg-white z-10"
              />
              <div
                class="absolute right-0 translate-x-1/2 -translate-y-1/2
                  rounded-full size-14 bg-white z-10"
              />
            </div>

            <p id="df-original" class="flex items-center justify-center gap-1">
              <DreamFlareWordmarkIcon class="h-4" />
              <span class="-mb-px text-lg font-extrabold">Original</span>
            </p>

            <Button
              id={props.id + '-btn'}
              variant="cta"
              size="lg"
              width="full"
              class="gap-2 font-bold text-base w-full"
              href={url}
            >
              <PlayIcon />
              <span>Play Now</span>
            </Button>
          </div>
        </div>
      </a>
    </Slide>
  );
}
