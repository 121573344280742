import { cn } from '~/utils';
import type { IconProps } from '../types';

export function KidsIcon(props: IconProps) {
  return (
    <svg
      fill="none"
      class={cn('size-6', props.class)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M462.885,382.541c-44.754-9.946-52.217,47.244-52.217,47.244s-37.371-1.417-42.895-15.695
		c-1.805-3.61-7.147-26.55-13.536-55.808c20.143,12.283,51.468,26.74,79.833,8.501c38.68-24.872,16.587-80.121-22.093-77.351
		c-24.105,1.724-53.588-10.504-77.72-26c-0.09-0.451-0.19-0.903-0.289-1.363c31.362-24.285,51.568-62.279,51.568-105.002
		c0-12.364-1.724-23.843-4.882-34.43c24.529-8.672,42.128-31.992,42.128-59.491C422.779,28.284,394.504,0,359.624,0
		c-29.159,0-53.625,19.783-60.899,46.658c-14.322-4.079-29.8-6.182-45.963-6.182c-13.898,0-27.309,1.57-39.898,4.594
		C205.076,19.007,180.962,0,152.371,0C117.5,0,89.225,28.284,89.225,63.146c0,25.296,14.9,47.046,36.37,57.136
		c-3.638,11.217-5.614,23.509-5.614,36.784c0,44.447,21.868,83.759,55.404,107.854c-23.681,14.765-52.028,26.181-75.356,24.512
		c-38.68-2.77-60.782,52.479-22.102,77.351c28.192,18.121,59.319,3.952,79.472-8.276c-6.218,28.482-11.398,51.008-13.167,55.583
		c-5.524,14.278-42.904,15.695-42.904,15.695s-7.454-57.19-52.208-47.244c-33.013,7.336-40.332,41.784-19.891,77.07
		c27.345,47.244,43.987,53.625,79.562,52.217c31.496-1.236,108.188-3.348,147.211-3.348s115.715,2.112,147.212,3.348
		c35.575,1.408,52.217-4.973,79.562-52.217C503.207,424.325,495.897,389.877,462.885,382.541z M256.002,239.643
		c-36.216,0-65.583-21.028-65.583-46.956c0-25.937,29.367-46.964,65.583-46.964c36.208,0,65.574,21.028,65.574,46.964
		C321.576,218.615,292.21,239.643,256.002,239.643z"
          fill="currentcolor"
        />
        <path
          d="M256.002,161.922c-13.862,0-25.098,7.608-25.098,17.003c0,9.386,11.236,16.993,25.098,16.993
		s25.098-7.607,25.098-16.993C281.1,169.53,269.864,161.922,256.002,161.922z"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}
