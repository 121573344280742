import type { Component } from 'solid-js';
import type { AuthUser } from '~/libs/api-client';
import { Action } from './Action';
import { ACTION_RUNNERS } from './runners';
import type { OneTimeActionExecutionContext } from './types';

let actionsScheduled = false;

async function runOneTimeActions(user?: AuthUser) {
  if (!user) return;

  const runnableActions = (user?.pendingActions || [])
    .map(id => new Action(id, user))
    .filter(a => a.hasRunner);

  for (const action of runnableActions) {
    if (action.isPending) {
      try {
        await action.run({ user });
      } catch (error) {
        console.log('🅰️ error running', error);
      }
    }
  }
}

export function scheduleOneTimeActions(user?: AuthUser) {
  if (actionsScheduled || !user) return;

  if (document.readyState === 'complete') {
    runOneTimeActions(user);
  } else {
    window.addEventListener('load', () => runOneTimeActions(user));
  }

  actionsScheduled = true;
}

export const OneTimeActionsProvider: Component<
  OneTimeActionExecutionContext
> = (props: OneTimeActionExecutionContext) => {
  scheduleOneTimeActions(props.user);

  const runnerComponents = Object.values(ACTION_RUNNERS)
    .map(runner => {
      return runner.component;
    })
    .filter((c): c is Component<OneTimeActionExecutionContext> => !!c);

  console.log('🅰️ OneTimeActionsProvider', runnerComponents);

  return <>{runnerComponents.map(comp => comp(props))}</>;
};
