import type { OneTimeAction } from '~/libs/api-client';
import { type AuthUser } from '~/libs/api-client';
import type { OneTimeActionExecutionContext } from './types';

import { ACTION_RUNNERS } from './runners';
import { completeAction } from './runners/commons';

export class Action {
  constructor(
    public readonly id: OneTimeAction,
    public readonly user: AuthUser,
  ) {}

  get isCompleted(): boolean {
    return Boolean(localStorage?.getItem(`completedActions.${this.id}`));
  }

  get isPending(): boolean {
    return Boolean(
      this.hasRunner &&
        !this.isCompleted &&
        this.user.pendingActions.includes(this.id),
    );
  }

  get hasRunner() {
    return Boolean(ACTION_RUNNERS[this.id]);
  }

  private async complete(user: AuthUser) {
    if (this.isCompleted) return;
    await completeAction(this.id, user);
  }

  public async run(ctx: OneTimeActionExecutionContext) {
    if (this.hasRunner && this.isPending) {
      const shouldComplete = await ACTION_RUNNERS[this.id].run(ctx);
      if (shouldComplete && ctx.user) {
        await this.complete(ctx.user);
      }
    }
  }
}
