import { For } from 'solid-js';
import type { ContentDto } from '~/modules/api';
import { isEmptyArray } from '~/utils';
import { Slide } from '../ui';
import { CarouselGallery } from './CarouselGallery';
import { Tile } from './tiles';

export interface RecommendedStoriesGalleryProps {
  items: ContentDto[];
}

export function RecommendedStoriesGallery(
  props: RecommendedStoriesGalleryProps,
) {
  if (isEmptyArray(props.items)) {
    return null;
  }

  return (
    <CarouselGallery
      id="recommendations-carousel"
      heading={{
        title: 'Recommended For You',
      }}
      hideArrows
    >
      <For each={props.items}>
        {post => (
          <Slide class="df-scrollable-list-item-4-col">
            <Tile
              preset="VerticalTileCondensed"
              content={post}
              trackingProperties={{ section: 'recommended-for-you' }}
            />
          </Slide>
        )}
      </For>
    </CarouselGallery>
  );
}
