import type { AuthUser, OneTimeAction } from '~/libs/api-client';
import { api } from '~/modules/api';

export async function completeAction(actionId: OneTimeAction, user?: AuthUser) {
  if (user) {
    user.pendingActions = user?.pendingActions.filter(id => id !== actionId);
  }

  await api.User.removePendingAction({
    path: {
      oneTimeAction: actionId,
    },
  }).then((res: { error: any }) => {
    if (res.error) {
      console.error(
        `Error marking action '${actionId}' as completed:`,
        res.error,
      );
      return;
    }

    localStorage?.removeItem(`completedActions.${actionId}`);
  });
}
